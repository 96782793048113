.credits-container{
    background-color: white;
    width: 90vw;
    max-width: 600px;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.321);
    line-height: 1.5em;
    text-align: justify;
    margin: 20px auto;
}
.credits-container > p{
    text-indent: 30px;
}
.credits-container a{
    text-decoration: none;
}
.credits-container a:hover{
    text-decoration:underline;
}
