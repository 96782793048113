.start-screen{
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 90vw;
    max-width: 90vh;
    height: 90vh;
    translate: -50% -50%;
    overflow: scroll;
    border-radius: 20px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.522);
    z-index: 2;
}
.start-screen__basic-info{
    text-align: justify;
    padding: 20px;
}
.start-screen__basic-info>h1{
    text-align: center;
}
.start-screen__characters-container{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-evenly;
}
.start-button{
    padding: 15px;
    margin: 10px;
    border-radius: 10px;
    background-color: #ff0000;
    color: white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.479);
    font-weight: bold;
}
.start-button:active{
    background-color: #ff0000cc;
}