.rankings-container{
    margin: auto;
    margin-top: 10px;
    width: 80vw;
    max-width: 600px;
    text-align: center;
    vertical-align: middle;
    line-height: 2em;
    font-size: 2em;
    border-spacing: 0px;
    border: 1px solid black;
    color: white;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.342);
}

.rankings-container *{
    border: 1px solid #FA1B01;
}
@media only screen and (max-width: 320px){
    .rankings-container{
        font-size: 1em;
    }
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
    .rankings-container{
        font-size: 1.5em;
    }
}
