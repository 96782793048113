@charset "UTF-8";

*{
  margin: 0px;
  padding: 0px;
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}
body{
  background-color: #161153;
}
.App{
  height: 1px;
  min-height: 100vh;
  width: 100vw;
}
.background-mask::before {
  content: "";
  position: fixed;
  z-index: 1;
  min-height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.75);  
}

::-webkit-scrollbar{
  display: none;
}
.game-image{
  width: 100vw;
}
.message{
  position: fixed;
  font-weight: bold;
  background-color: #1fb71f;
  color: white;
  padding: 10px;
  border-radius: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.492);
  top: 10px;
  left: 50%;
  translate: -50%;
}
.message--red{
  background-color: red;
}