.character-card{
    flex-grow: 1;
    background-color: white;
    display: flex;
    height: 80px;
}
.character-card__info{
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.character-card__info__name{
    font-size: 1.4em;
    margin: 5px;
}
.character-card__image-container{
    width: 50%;
}
.character-card__image{
    display: block;
    margin: auto;
    height: 100%;
}